<template>
  <div class="noauth">
    <h1>
      抱歉，您当前没有权限访问本产品。如需开通权限，请联系您的客户经理，或通过邮件联系我们：business@smartfindata.com
      (鑫讯科技-商务合作邮箱)。
    </h1>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.noauth {
  color: #fff;
}
</style>
